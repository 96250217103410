
import { PropType, defineComponent } from 'vue';
import isEqual from 'lodash/isEqual';
import { TTTreeItem, ValueConsistOf } from './types';
import prepareValueForBranchPriority from './prepareValueForBranchPriority';

export default defineComponent({
  name: 'TTTreeSelectable',

  props: {
    value: {
      type: Array as PropType<TTTreeItem[]>,
      default: () => [],
    },
    items: {
      type: Array as PropType<TTTreeItem[]>,
      default: () => [],
    },
    valueConsistOf: {
      type: String as PropType<ValueConsistOf>,
      default: () => ValueConsistOf.BRANCH_PRIORITY,
    },
    open: {
      type: Array as PropType<TTTreeItem[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focusItem: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      internalValue: [] as TTTreeItem[],
    };
  },

  computed: {
    preparedInternalValue(): TTTreeItem[] {
      return this.prepareValue(this.internalValue);
    },
  },

  watch: {
    value(newValue: TTTreeItem[]) {
      const newValueIds = newValue.map((item) => item.id);
      const internalValueIds = this.preparedInternalValue.map((item) => item.id);
      const valuesAreEqual = isEqual(newValueIds, internalValueIds);
      if (!valuesAreEqual) {
        this.internalValue = newValue;
      }
    },
    focusItem(newValue) {
      if (!newValue) return;
      this.setTreeItemFocus();
    },
  },

  created() {
    this.internalValue = this.value;
  },

  methods: {
    prepareValue(value: TTTreeItem[]): TTTreeItem[] {
      if (this.valueConsistOf === ValueConsistOf.LEAF_PRIORITY) {
        return value;
      }

      return prepareValueForBranchPriority(this.items, value);
    },
    onTreeViewInput(value: TTTreeItem[]) {
      this.$emit('input', this.prepareValue(value));
    },
    onTreeViewOpen(value: TTTreeItem[]) {
      this.$emit('update:open', value);
    },
    async setTreeItemFocus() {
      await this.$nextTick();
      const { id } = this.focusItem;
      const focusItemRef = this.$refs[`tree-item_${id}`];
      if (!focusItemRef) return;
      focusItemRef.classList.add('item-focus');
      focusItemRef.setAttribute('tabindex', '0');
      focusItemRef.focus();
    },
    resetItemFocus(e) {
      const el = e?.target || null;
      if (el) {
        el.classList.remove('item-focus');
      }
    },
  },
});
