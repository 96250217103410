
import Vue, { PropType } from 'vue';
import HeatMap from '@/components/analytics/charts/HeatMap/HeatMap.vue';
import SkeletonTableStriped from '@/components/common/skeletons/SkeletonTableStriped.vue';
import { HeatMapColumn, HeatMapRow as HeatMapRowType } from '@/components/analytics/charts/HeatMap/types';
import {
  V1EntitiesAnalyticsManagementDifferenceHeatmapQuestion,
  V1EntitiesAnalyticsManagementDifferenceHeatmapUser,
  V1EntitiesAnalyticsManagementDifferenceHeatmapSettings,
} from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';

const getUserColumnName = (userId: string) => `user-${userId}`;

export default Vue.extend({
  name: 'CompetencyDifferenceHeatMapWidget',

  components: {
    HeatMap,
    SkeletonTableStriped,
  },

  props: {
    surveyId: {
      type: Number,
      required: true,
    },
    userIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },

  data() {
    return {
      chartData: {
        questions: [] as V1EntitiesAnalyticsManagementDifferenceHeatmapQuestion[],
        users: [] as V1EntitiesAnalyticsManagementDifferenceHeatmapUser[],
        settings: {
          minValue: 1,
          maxValue: 5,
        } as V1EntitiesAnalyticsManagementDifferenceHeatmapSettings,
      },
      loading: false,
    };
  },

  computed: {
    chartDataIsNotEmpty(): boolean {
      return !!this.chartData.questions?.length && !!this.chartData.users?.length;
    },
    rows(): HeatMapRowType[] {
      const preparedRows = this.chartData.questions.map((question) => ({
        ...question,
        ...Object.fromEntries(question.values.map(({ userId, value, options }) => ([
          getUserColumnName(userId),
          this.getValue(value, options),
        ]))),
      }));

      const itemsMap: Record<string | number, HeatMapRowType> = Object.fromEntries(
        preparedRows.map((item) => [item.id, { ...item, children: [] }]),
      );
      const rootItems: HeatMapRowType[] = [];

      Object.values(itemsMap).forEach((item) => {
        if (!item.parentId || item.parentId === item.id || !itemsMap[item.parentId]) {
          rootItems.push(item);
        } else {
          itemsMap[item.parentId].children!.push(item);
        }
      });
      return rootItems;
    },

    usersCols() {
      return this.chartData.users.map((user) => ({
        key: getUserColumnName(user.userId),
        title: user.name,
        hint: user.name,
        isFixed: false,
        headerCellClass: 'white-space-normal d-flex align-center',
        align: 'center',
      }));
    },

    columns(): HeatMapColumn[] {
      if (!this.chartData) {
        return [];
      }

      return [
        {
          key: 'name',
          title: this.$t('analytics.feedback.valuesAndCompetencies') as string,
          isFixed: true,
          width: 300,
          cellClass: 'tt-light-mono-100--text',
          headerCellClass: 'd-flex align-center',
        },
        ...this.usersCols,
      ];
    },
    // Параметры, при изменении которых необходимо перезапросить данные
    requestParams(): any {
      return {
        surveyId: this.surveyId,
        userIds: [...this.userIds],
      };
    },
  },

  watch: {
    requestParams: {
      immediate: true,
      handler() {
        this.loadChartData();
      },
    },
  },

  methods: {
    async loadChartData() {
      try {
        this.loading = true;

        const { data } = await tsxassApi.postV1AnalyticsManagementDifferenceHeatmap({
          surveyId: this.surveyId,
          userIds: this.userIds,
        });
        this.chartData = {
          questions: data.questions || [],
          users: data.users || [],
          settings: data.settings || { minValue: 1, maxValue: 5 },
        };
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    getValue(value, options) {
      if (!options || !options.anonymityThresholdReached) {
        return value && Number(this.chartData.settings.minValue === -100 ? value.toFixed(0) : value.toFixed(1));
      }
      return this.$t('analytics.baseCompetencies.heatMap.anonymityThresholdReached');
    },
  },
});
