
import Vue, { PropType } from 'vue';
import {
  V1EntitiesAnalyticsManagementAudiencesAudience,
} from '@/services/api/tsxass';
import UserAvatar from '@/components/common/UserAvatar.vue';
import debounce from 'lodash/debounce';
import TTSimpleCheckbox from '@/components/ui/TTSimpleCheckbox.vue';

type ValueType = V1EntitiesAnalyticsManagementAudiencesAudience
| V1EntitiesAnalyticsManagementAudiencesAudience[] | null;

export default Vue.extend({
  name: 'UserSelect',

  components: {
    UserAvatar,
    TTSimpleCheckbox,
  },

  props: {
    value: {
      type: [Object, Array, null] as PropType<ValueType>,
      default: null,
    },
    users: {
      type: Array as PropType<V1EntitiesAnalyticsManagementAudiencesAudience[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: [Boolean, String, Object],
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchText: '',
    };
  },

  computed: {
    internalValue: {
      get(): ValueType {
        return this.value;
      },
      set(value: ValueType) {
        this.$emit('input', value);
      },
    },
    selectedItemsArray(): V1EntitiesAnalyticsManagementAudiencesAudience[] {
      const items = Array.isArray(this.internalValue)
        ? this.internalValue
        : this.internalValue ? [this.internalValue] : [];
      return items;
    },
    selectedItemIds(): string[] {
      return this.selectedItemsArray.map((user) => user.userId);
    },
  },

  watch: {
    searchText: {
      immediate: true,
      handler(newValue) {
        this.searchUsers(newValue);
      },
    },
  },

  created() {
    this.searchUsers = debounce(this.searchUsers, 300);
  },

  methods: {
    searchUsers(searchText: string) {
      this.$emit('search', searchText);
    },
    onCheckboxInput(item: V1EntitiesAnalyticsManagementAudiencesAudience, value: boolean) {
      const arrayValue = Array.isArray(this.internalValue) ? this.internalValue : [];
      const selectedUsers = value
        ? [...arrayValue, item]
        : arrayValue.filter((user) => user.userId !== item.userId);
      this.internalValue = selectedUsers;
    },
  },
});
