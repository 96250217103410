
import Vue from 'vue';
import { mapState } from 'vuex';
import SurveyAndSurveyeeSelectDialog from '@/components/analytics/feedback/SurveyAndSurveyeeSelectDialog.vue';
import { SurveyAndSurveyees } from '@/components/analytics/feedback/types';
import NoDataStub from '@/components/common/NoDataStub.vue';
import {
  V1EntitiesAnalyticsManagementSurveysSurvey,
  V1EntitiesAudiencesPublicSearchIndexAudience,
} from '@/services/api/tsxass';
import CompetencyDifferenceHeatMapWidget from '@/components/analytics/widgets/CompetencyDifferenceHeatMapWidget.vue';
import localStorageService from '@/services/localStorage';
import LocalStorageItem, { EmployeesComparisonParams } from '@/constants/localStorageItem';
import { tsxassApi } from '@/services/api';
import errorHandler from '@/helpers/errorHandler';
import ExportCompetenciesToExcel from '@/views/app/AnalyticsUser/ExportCompetenciesToExcel.vue';

export default Vue.extend({
  name: 'EmployeeResultsComparison',

  components: {
    SurveyAndSurveyeeSelectDialog,
    NoDataStub,
    CompetencyDifferenceHeatMapWidget,
    ExportCompetenciesToExcel,
  },

  data() {
    return {
      showParamsDialog: false,
      survey: null as V1EntitiesAnalyticsManagementSurveysSurvey | null,
      surveyees: [] as V1EntitiesAudiencesPublicSearchIndexAudience[],
      surveyLoading: false,
      surveyeesLoading: false,
    };
  },

  computed: {
    ...mapState('engagementInfo', { currentUser: 'user' }),

    paramsList(): { name: string, label: string, value: any }[] {
      return [
        {
          name: 'surveyName',
          label: `${this.$t('analytics.feedback.surveyName')}:`,
          value: this.survey?.name || '',
        },
        {
          name: 'employeesCount',
          label: `${this.$t('analytics.feedback.employeesCount')}:`,
          value: this.surveyees.length,
        },
      ];
    },
    surveyeeIds(): string[] {
      return this.surveyees.map((item) => item.userId);
    },
  },

  mounted() {
    this.restoreSavedParams();
  },

  methods: {
    updateParams(e: SurveyAndSurveyees) {
      this.surveyees = e.surveyees;
      this.survey = e.survey;
      this.saveParams();
    },
    // Сохранить данные о выбранных параметрах в localStorage
    saveParams() {
      localStorageService.set(LocalStorageItem.EMPLOYEES_COMPARISON_PARAMS, {
        userId: this.currentUser.userId,
        surveyId: this.survey?.id,
        surveyeeIds: this.surveyeeIds,
      });
    },
    // Восстановить параметры из localStorage
    async restoreSavedParams() {
      const params: null | EmployeesComparisonParams = localStorageService
        .get(LocalStorageItem.EMPLOYEES_COMPARISON_PARAMS);
      if (!params) {
        return;
      }
      if (params.userId !== this.currentUser?.userId) {
        localStorageService.remove(LocalStorageItem.EMPLOYEES_COMPARISON_PARAMS);
        return;
      }

      if (params.surveyId) {
        this.loadSurvey(params.surveyId);
      }
      if (params.surveyeeIds?.length) {
        this.loadSurveyees(params.surveyeeIds);
      }
    },
    async loadSurvey(surveyId: number) {
      try {
        this.surveyLoading = true;

        const { data: survey } = await tsxassApi.getV1SurveysSurveyId(surveyId);
        this.survey = survey;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.surveyLoading = false;
      }
    },
    async loadSurveyees(surveyeeIds: string[]) {
      try {
        this.surveyeesLoading = true;

        const { data: { users } } = await tsxassApi.postV1AnalyticsManagementSubordinateByIds({
          userIds: surveyeeIds,
        });
        this.surveyees = users;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.surveyeesLoading = false;
      }
    },
  },
});
