
import Vue, { PropType } from 'vue';
import { PageAndLimit } from '@/services/api/common/types';
import { tsxassApi } from '@/services/api';
import {
  V1EntitiesAudiencesPublicSearchIndexAudience,
  V1EntitiesAnalyticsManagementSurveysSurvey,
} from '@/services/api/tsxass';
import { DEFAULT_PAGINATION } from '@/constants/pagination';
import errorHandler from '@/helpers/errorHandler';
import UserAddingDialog from '@/components/common/UserAddingDialog.vue';
import SurveySelect from '@/components/analytics/feedback/SurveySelect.vue';
import SectionTitle from '@/components/common/SectionTitle.vue';
import { SurveyAndSurveyees } from './types';

interface PaginationAndSearch {
  pagination: PageAndLimit;
  search?: string;
}

export default Vue.extend({
  name: 'SurveyAndSurveyeeSelectDialog',

  components: {
    UserAddingDialog,
    SurveySelect,
    SectionTitle,
  },

  props: {
    show: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    survey: {
      type: [Object, null] as PropType<V1EntitiesAnalyticsManagementSurveysSurvey | null>,
      default: null,
    },
    surveyees: {
      type: Array as PropType<V1EntitiesAudiencesPublicSearchIndexAudience[]>,
      default: () => [],
    },
  },

  data() {
    return {
      audience: [] as V1EntitiesAudiencesPublicSearchIndexAudience[],
      audiencePagination: { ...DEFAULT_PAGINATION },
      audienceLoading: false,
      // Выбор опроса
      surveys: [] as V1EntitiesAnalyticsManagementSurveysSurvey[],
      surveysLoading: false,
    };
  },

  computed: {
    computedShow: {
      get(): boolean {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
    selectedSurvey: {
      get(): V1EntitiesAnalyticsManagementSurveysSurvey | null {
        return this.survey;
      },
      set(value: V1EntitiesAnalyticsManagementSurveysSurvey | null) {
        this.submitParams({
          surveyees: [],
          survey: value,
        });
      },
    },
  },

  watch: {
    selectedSurvey() {
      this.resetState();
      this.loadAudiences({
        pagination: this.audiencePagination,
        search: '',
      });
    },
  },

  mounted() {
    this.loadSurveys();
  },

  methods: {
    async loadSurveys() {
      try {
        this.surveysLoading = true;

        const { data: { surveys } } = await tsxassApi
          .getV1AnalyticsManagementSurveys();
        this.surveys = surveys || [];
      } catch (err) {
        errorHandler(err);
      } finally {
        this.surveysLoading = false;
      }
    },
    resetState() {
      this.audience = [];
      this.audiencePagination = { ...DEFAULT_PAGINATION };
      this.audienceLoading = false;
    },
    async loadAudiences({ pagination: { limit, page }, search }: PaginationAndSearch) {
      try {
        this.audienceLoading = true;
        const { data: { audiences = [], pagination } } = await tsxassApi.getV1AnalyticsManagementAudiences(
          this.selectedSurvey?.id || undefined,
          search || '',
          page,
          limit,
        );

        this.audiencePagination = pagination;
        this.audience.splice((page - 1) * limit, this.audience.length, ...audiences);
      } catch (err) {
        errorHandler(err);
      } finally {
        this.audienceLoading = false;
      }
    },
    addUsers(users: V1EntitiesAudiencesPublicSearchIndexAudience[]) {
      this.submitParams({
        surveyees: [...users],
        survey: this.selectedSurvey,
      });
      this.computedShow = false;
    },
    submitParams(payload: SurveyAndSurveyees) {
      this.$emit('submit', payload);
    },
  },
});
