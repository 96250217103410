import { TTTreeItem, SelectedItemsMap } from './types';

export default function prepareValueForBranchPriority(items: TTTreeItem[], value: TTTreeItem[]): TTTreeItem[] {
  // Map-объект с элементами дерева.
  // Инициализируем уже выбранными элементами.
  const selectedItemsMap: SelectedItemsMap = Object.fromEntries(
    value.map((item) => [item.id, { item, selected: true }]),
  );

  const addItemToMap = (item: TTTreeItem, selected: boolean) => {
    selectedItemsMap[item.id] = {
      item,
      selected,
    };
  };

  // Заполняет selected у всех детей элемента и у самого элемента, если все его дети выбраны.
  // Возвращает собственное значение selected.
  const areAllChildrenSelected = (item: TTTreeItem): boolean => {
    // Если значение узла уже просчитано - вернуть его.
    if (selectedItemsMap[item.id]) {
      return selectedItemsMap[item.id].selected;
    }

    // Если нет детей, отметить selected = false и вернуть.
    if (!item.children || !item.children.length) {
      addItemToMap(item, false);
      return selectedItemsMap[item.id].selected;
    }

    // Заполнить selected у всех детей.
    const childrenRes = item.children
      .reduce((res: boolean[], child: TTTreeItem) => {
        res.push(areAllChildrenSelected(child));
        return res;
      }, []);

    const allChildrenAreSelected = childrenRes.every((childIsSelected) => childIsSelected);

    // Если все дети выбраны, то выбран и текущий элемент.
    addItemToMap(item, allChildrenAreSelected);

    return selectedItemsMap[item.id].selected;
  };

  // Возвращает выбранные узлы из ветвей.
  const getSelectedItemsAtHighLevel = (branches: TTTreeItem[]) => {
    const result: TTTreeItem[] = [];
    branches.forEach((branch) => {
      if (areAllChildrenSelected(branch)) {
        result.push(branch);
      } else {
        result.push(...getSelectedItemsAtHighLevel(branch.children));
      }
    });

    return result;
  };

  return getSelectedItemsAtHighLevel(items);
}
