
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import type { TranslateResult } from 'vue-i18n';
import { TsxassAbility, LicenseType } from '@/helpers/constants';
import errorHandler from '@/helpers/errorHandler';
import {
  V1EntitiesAnalyticsManagementSurveysSurvey,
  V1EntitiesPaginateOutput,
  V1EntitiesAnalyticsManagementResultForUsersAudience,
  V1EntitiesAnalyticsManagementResultForUsersItem,
  V1EntitiesAnalyticsManagementAudiencesAudience,
  PostV1AnalyticsManagementResultOrderBy,
  PostV1AnalyticsManagementResultDirection,
  V1EntitiesAnalyticsManagementExpertListBySurveyItemExpertList,
} from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import * as reportServiceApi from '@/services/api/report-service';
import SurveySelect from '@/components/analytics/feedback/SurveySelect.vue';
import UserSelect from '@/components/analytics/feedback/UserSelect.vue';
import UsersTable from '@/components/analytics/feedback/UsersTable.vue';
import SelectUsersGroup from '@/components/analytics/widgets/SelectUsersGroup.vue';
import ExportMenu from '@/components/analytics/widgets/ExportMenu.vue';
import GradeValueSmile from '@/components/analytics/charts/GradeValueSmile.vue';
import ProgressWithPercentage from '@/components/analytics/charts/ProgressWithPercentage.vue';
import ExportDialog from '@/components/common/ExportDialog.vue';
import { UsersTableRow } from '@/components/analytics/feedback/types';
import { DEFAULT_PAGINATION as GLOBAL_DEFAULT_PAGINATION } from '@/constants/pagination';
import isEqual from 'lodash/isEqual';
import timeoutPromise from '@/helpers/promisify';

interface SortingParams {
  orderBy?: PostV1AnalyticsManagementResultOrderBy;
  direction?: PostV1AnalyticsManagementResultDirection;
}

interface HeaderItem {
  text: TranslateResult | string;
  value: string;
  width: string;
  cellClass?: string;
  hidden?: boolean;
  sortable?: boolean;
  fieldNameForSorting?: string;
}

interface SelectGroup {
  id: number | null;
  name: string;
  withoutSelf?: boolean;
}

const DEFAULT_PAGINATION = { ...GLOBAL_DEFAULT_PAGINATION, limit: 40 };

export default Vue.extend({
  name: 'EmployeeResultsPivotTable',

  components: {
    SurveySelect,
    UserSelect,
    UsersTable,
    SelectUsersGroup,
    ProgressWithPercentage,
    GradeValueSmile,
    ExportDialog,
    ExportMenu,
  },

  inject: ['RouteNames'],

  data() {
    return {
      // Выбор опроса
      surveys: [] as V1EntitiesAnalyticsManagementSurveysSurvey[],
      surveysLoading: false,
      selectedSurvey: null as V1EntitiesAnalyticsManagementSurveysSurvey | null,
      // Выбор пользователя
      selectedUsers: [] as V1EntitiesAnalyticsManagementAudiencesAudience[],
      userSelectItems: [] as V1EntitiesAnalyticsManagementAudiencesAudience[],
      userSelectLoading: false,
      userSearchText: '',
      // Таблица пользователей
      userResults: {
        users: [] as V1EntitiesAnalyticsManagementResultForUsersAudience[],
        result: [] as V1EntitiesAnalyticsManagementResultForUsersItem[],
        pagination: { ...DEFAULT_PAGINATION } as V1EntitiesPaginateOutput,
      },
      usersLoading: false,
      usersSorting: {
        orderBy: ['user'],
        direction: [true],
      },
      groups: [] as V1EntitiesAnalyticsManagementExpertListBySurveyItemExpertList[],
      selectedGroup: null as SelectGroup | null,
      groupSelectLoading: false,
      isShowExportDialog: false,
      exportType: '',
      exportDialogUsersLoadingProgress: {
        loaded: 0,
        total: 0,
      },
      exportDialogArchiveStatus: undefined,
    };
  },

  computed: {
    ...mapGetters('engagementInfo', {
      can: 'can',
    }),
    ...mapState('profile', {
      profile: 'profile',
      licenses: 'licenses',
    }),
    ...mapState('engagementInfo', { currentUser: 'user' }),
    hasExportToExcelLicense(): boolean {
      return this.licenses.some((name: string) => name === LicenseType.TSXASS_360_EXCEL);
    },
    hasHideLicense() {
      const { profile } = this;
      const hasHideLicense = this.licenses.some((name: string) => name === LicenseType.HIDE_EXPERT_GROUPS);
      const canEditSurvey = this.can(TsxassAbility.CAN_EDIT_ANY_SURVEY_360);
      return (hasHideLicense && !canEditSurvey) || !profile;
    },
    latestSurvey(): V1EntitiesAnalyticsManagementSurveysSurvey | null {
      if (!this.surveys.length) {
        return null;
      }
      const sorted = [...this.surveys];
      // eslint-disable-next-line arrow-body-style
      sorted.sort((a, b) => {
        return this.$dayjs(a.dateEnd).isAfter(this.$dayjs(b.dateEnd)) ? 1 : -1;
      });

      return sorted[sorted.length - 1];
    },
    userResultsList(): UsersTableRow[] {
      const usersMap = Object.fromEntries(this.userResults.users.map((user) => [user.userId, user]));
      return this.userResults.result.map((res) => ({
        user: usersMap[res.userId],
        minScale: res.settings?.minValue || 0,
        maxScale: res.settings?.maxValue || 10,
        surveyId: res.surveyId,
        surveyName: res.surveyName,
        benchAvg: res.benchAvg,
        anonimityReached: !res.averageOptions?.display && res.averageOptions?.anonymityThresholdReached,
        developmentTasksInvalidWeights: res.developmentTasksInvalidWeights,
        developmentTasksProgress: res.developmentTasksProgress,
      }));
    },
    selectedUserIds(): string[] {
      return (this.selectedUsers || []).map((user) => user.userId);
    },
    hasAverageScoreColumn(): boolean {
      return this.selectedSurvey?.withBenchAvg || !this.selectedSurvey?.id;
    },
    has360DevelopmentPlanLicense() {
      return this.licenses.some((name: string) => name === LicenseType.TSXASS);
    },
    headers(): HeaderItem[] {
      return [
        {
          text: this.$t('userForRate.employee') as string,
          value: 'user',
          width: '25%',
          sortable: true,
          fieldNameForSorting: PostV1AnalyticsManagementResultOrderBy.EMPLOYEE,
        },
        {
          text: this.$t('userForRate.team') as string,
          value: 'user.teamsWithDelimiter',
          width: this.hasAverageScoreColumn ? '15%' : '30%',
          sortable: false,
        },
        {
          text: this.$t('survey') as string,
          value: 'surveyName',
          width: this.hasAverageScoreColumn ? '15%' : '25%',
          cellClass: 'one-line-text tt-text-body-2 tt-light-mono-64--text',
          sortable: false,
        },
        {
          text: this.$t('analytics.baseCompetencies.results') as string,
          value: 'benchAvg',
          width: '15%',
          cellClass: 'tt-text-body-2 tt-light-mono-64--text',
          hidden: !this.hasAverageScoreColumn,
          sortable: true,
          fieldNameForSorting: PostV1AnalyticsManagementResultOrderBy.BENCH_AVG,
        },
        {
          text: this.$t('analytics.developmentTasks.progressIpr') as string,
          value: 'developmentTasksProgress',
          width: '16%',
          hidden: !this.has360DevelopmentPlanLicense || this.hasHideLicense,
          cellClass: 'tt-text-body-2 tt-light-mono-64--text',
          sortable: false,
        },
        {
          text: '',
          value: 'actions',
          width: '20%',
          cellClass: 'text-end',
          sortable: false,
        },
      ].filter((col) => !col.hidden);
    },
    // Параметры сортировки для запроса списка результатов
    sortingParams(): SortingParams {
      const orderByHeaderItem = this.usersSorting.orderBy.length
        ? this.headers.find((header) => header.value === this.usersSorting.orderBy[0])
        : undefined;
      const orderBy = orderByHeaderItem ? orderByHeaderItem.fieldNameForSorting || orderByHeaderItem.value : undefined;
      const direction = this.usersSorting.direction.length
        ? { true: 'asc', false: 'desc' }[String(this.usersSorting.direction[0])]
        : undefined;
      return {
        orderBy: orderBy as PostV1AnalyticsManagementResultOrderBy | undefined,
        direction: direction as PostV1AnalyticsManagementResultDirection | undefined,
      };
    },
    surveyId(): number {
      return Number(this.$route?.query?.surveyId) || 0;
    },
    preSelectedSurvey(): V1EntitiesAnalyticsManagementSurveysSurvey | null {
      if (!this.surveys.length) {
        return null;
      }
      return this.surveys.find((survey) => survey.id === this.surveyId) || this.latestSurvey;
    },
    isGroupSelectDisabled(): Boolean {
      return !this.selectedSurvey?.id || !this.selectedSurvey.withBenchAvg;
    },
    isExportMenuDisabled(): Boolean {
      // Засериваем меню выгрузки, если выбран вариант Все опросы
      return !this.selectedSurveyId;
    },
    selectedSurveyId() {
      return this.selectedSurvey?.id;
    },
  },

  watch: {
    selectedSurvey() {
      this.loadUserSelectItems(this.userSearchText);
      this.resetUsersPagination();
      this.resetSelectedGroup();
      this.loadUsers(this.userResults.pagination);
      this.loadGroups();
    },
    selectedUsers() {
      this.loadSurveys();
      this.resetUsersPagination();
      this.loadUsers(this.userResults.pagination);
    },
    selectedGroup() {
      this.resetUsersPagination();
      this.loadUsers(this.userResults.pagination);
    },
    usersSorting(prev, next) {
      if (isEqual(prev, next)) return;
      this.resetUsersPagination();
      this.loadUsers(this.userResults.pagination);
    },
  },

  async mounted() {
    await this.loadSurveys();
    this.selectedSurvey = this.preSelectedSurvey;
  },

  methods: {
    async loadSurveys() {
      try {
        this.surveysLoading = true;

        const {
          data: { surveys },
        } = await tsxassApi.postV1AnalyticsManagementSurveys({ userIds: this.selectedUserIds || undefined });
        this.surveys = surveys || [];
      } catch (err) {
        errorHandler(err);
      } finally {
        this.surveysLoading = false;
      }
    },
    async loadUserSelectItems(searchText: string) {
      this.userSearchText = searchText;

      try {
        this.userSelectLoading = true;

        const {
          data: { audiences },
        } = await tsxassApi.getV1AnalyticsManagementAudiences(
          this.selectedSurvey?.id || undefined,
          searchText || undefined,
        );
        this.userSelectItems = audiences || [];
      } catch (err) {
        errorHandler(err);
      } finally {
        this.userSelectLoading = false;
      }
    },
    async loadGroups() {
      if (!this.selectedSurvey?.id) {
        this.resetSelectedGroup();
        return;
      }
      try {
        this.groupSelectLoading = true;
        const {
          data: { expertLists },
        } = await tsxassApi.postV1AnalyticsManagementExpertLists({
          surveyId: this.selectedSurvey.id,
        });
        this.groups = expertLists;
      } catch (err) {
        errorHandler(err);
      } finally {
        this.groupSelectLoading = false;
      }
    },
    resetUsersPagination() {
      this.userResults.pagination = { ...DEFAULT_PAGINATION };
    },
    resetSelectedGroup() {
      this.selectedGroup = null;
    },
    async loadUsers({ limit, page }: { limit: number; page: number }) {
      try {
        this.usersLoading = true;

        const { data } = await tsxassApi.postV1AnalyticsManagementResult({
          surveyId: this.selectedSurvey?.id || undefined,
          userIds: this.selectedUserIds || undefined,
          orderBy: this.sortingParams.orderBy,
          direction: this.sortingParams.direction,
          expertListId: this.selectedGroup?.id || undefined,
          withoutSelf: this.selectedGroup ? this.selectedGroup.withoutSelf : undefined,
          page,
          limit,
        });

        const updatedUsers = [...this.userResults.users];
        updatedUsers.splice((page - 1) * limit, updatedUsers.length, ...data.users);
        const updatedResult = [...this.userResults.result];
        updatedResult.splice((page - 1) * limit, updatedResult.length, ...data.result);

        this.userResults = {
          users: updatedUsers,
          result: updatedResult,
          pagination: data.pagination,
        };
      } catch (err) {
        errorHandler(err);
      } finally {
        this.usersLoading = false;
      }
    },
    async loadAllUsersInSurvey() {
      let page = 0;
      const limit = 100;
      let total = 999999;
      let users = [];
      this.exportDialogUsersLoadingProgress = {
        loaded: 0,
        total: 0,
      };
      const params = {
        surveyId: this.selectedSurvey?.id || undefined,
        userIds: this.selectedUserIds || undefined,
        orderBy: this.sortingParams.orderBy,
        direction: this.sortingParams.direction,
        expertListId: this.selectedGroup?.id || undefined,
        withoutSelf: this.selectedGroup ? this.selectedGroup.withoutSelf : undefined,
        limit,
      };

      while (users.length < total) {
        if (!this.isShowExportDialog) {
          users = [];
          break;
        }
        // eslint-disable-next-line
        await timeoutPromise(100);
        try {
          // eslint-disable-next-line
          const { data } = await tsxassApi.postV1AnalyticsManagementResult({
            ...params,
            // eslint-disable-next-line
            page: ++page,
          });
          total = data.pagination.total;
          users.push(...data.users);
          this.exportDialogUsersLoadingProgress = {
            loaded: users.length,
            total,
          };
        } catch (err) {
          errorHandler(err);
          break;
        }
      }

      return users;
    },
    async checkPdfArchiveStatus() {
      let result = null;
      try {
        const userId = this.currentUser?.userId;
        const surveyId = this.selectedSurvey?.id;
        result = await reportServiceApi.archiveStatus({
          params: {
            userId,
            surveyId,
          },
        });
      } catch (err) {
        errorHandler(err);
      }
      return result;
    },
    async handleExportMenuInput(isMenuOpen: boolean) {
      if (!isMenuOpen) return;
      const pdfArchiveStatus = await this.checkPdfArchiveStatus();
      if (pdfArchiveStatus) {
        this.exportDialogArchiveStatus = pdfArchiveStatus;
      }
    },
    handleExportMenuSelect({ id }: { id: string, name: string }) {
      this.exportType = id;
      this.isShowExportDialog = true;
    },
  },
});
