
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'SkeletonTableStriped',

  props: {
    cols: {
      type: [Number, String] as PropType<number | string>,
      default: 9,
    },
    rows: {
      type: [Number, String] as PropType<number | string>,
      default: 10,
    },
    headerHeight: {
      type: [Number, String] as PropType<number | string>,
      default: 200,
    },
    rowHeight: {
      type: [Number, String] as PropType<number | string>,
      default: 60,
    },
    firstColWidth: {
      type: [Number, String] as PropType<number | string>,
      default: 340,
    },
    colWidth: {
      type: [Number, String] as PropType<number | string>,
      default: 92,
    },
    headerTitleDirection: {
      type: String as PropType<'horizontal' | 'vertical'>,
      default: 'vertical',
    },
  },

  computed: {
    cssVars(): Record<string, any> {
      return {
        '--cols-count': this.cols,
        '--rows-count': this.rows,
        '--header-height': `${this.headerHeight}px`,
        '--row-height': `${this.rowHeight}px`,
        '--first-col-width': `${this.firstColWidth}px`,
        '--col-width': `${this.colWidth}px`,
      };
    },
  },
});
