type TreeItemId = string;

export interface FlatTreeItem<MetaType = never> {
  id: TreeItemId;
  name: string;
  parentId: TreeItemId | null;
  meta?: MetaType;
  index?: number;
}

export interface TreeItem<MetaType = never> extends FlatTreeItem<MetaType> {
  children: TreeItem<MetaType>[];
}

export function buildTreeFromFlatArray<NodeMeta = any>(flatTree: FlatTreeItem<NodeMeta>[]): TreeItem<NodeMeta>[] {
  const flatTreeCopy = flatTree.map((item) => ({ ...item, children: [] }));
  const itemsMap: Record<TreeItemId, TreeItem<NodeMeta>> = Object.fromEntries(
    flatTreeCopy.map((item) => [item.id, item]),
  );

  const rootItems: TreeItem<NodeMeta>[] = [];

  flatTreeCopy.forEach((item) => {
    if (item.parentId) {
      itemsMap[item.parentId]?.children.push(item);
    } else {
      rootItems.push(item);
    }
  });
  return rootItems;
}
