
import Vue from 'vue';
import SurveyCard from '@/components/analytics/feedback/SurveyCard.vue';
import errorHandler from '@/helpers/errorHandler';
import { V1EntitiesSurveysPublicIndexSurvey } from '@/services/api/tsxass';
import { tsxassApi } from '@/services/api';
import NoDataStub from '@/components/common/NoDataStub.vue';
import TTLoader from '@/components/ui/TTLoader.vue';

export default Vue.extend({
  name: 'TabUserResults',

  components: {
    SurveyCard,
    NoDataStub,
    TTLoader,
  },

  inject: ['RouteNames'],

  data() {
    return {
      loading: false,
      surveys: [] as V1EntitiesSurveysPublicIndexSurvey[],
    };
  },

  mounted() {
    this.loadSurveys();
  },

  methods: {
    async loadSurveys() {
      try {
        this.loading = true;

        const { data: { surveys } } = await tsxassApi.getV1AnalyticsSurveys();
        this.surveys = surveys || [];
      } catch (err) {
        errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
});
