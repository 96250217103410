
import Vue from 'vue';
import { mapState } from 'vuex';
import { LicenseType } from '@/helpers/constants';

interface MenuItem {
  id: string;
  name: string;
  disabled: boolean;
  disabledHint?: string;
  icon?: string;
  isVisible: boolean;
}

export default Vue.extend({
  name: 'ExportMenu',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    pdfArchiveStatus: {
      type: Object,
      default: () => ({ done: 0, total: 0, running: false }),
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState('profile', {
      licenses: 'licenses',
    }),
    hasExportToExcelLicense(): boolean {
      return this.licenses.some((name: string) => name === LicenseType.TSXASS_360_EXCEL);
    },
    has360AtonLicense(): boolean {
      return this.licenses.some((name: string) => name === LicenseType.ATON_360);
    },
    menuItems(): MenuItem[] {
      const menuList: MenuItem[] = [
        {
          id: 'pdf',
          name: this.$t('exportMenu.exportToPdf') as string,
          disabled: this.pdfArchiveStatus.running,
          disabledHint: this.$t('exportMenu.disabledHintPdf') as string,
          icon: 'fa fal fa-file-pdf',
          isVisible: !this.has360AtonLicense,
        },
        {
          id: 'excel',
          name: this.$t('exportMenu.exportToExcel') as string,
          disabled: false,
          disabledHint: '',
          icon: 'fa fal fa-file-excel',
          isVisible: this.hasExportToExcelLicense,
        },
      ];
      return menuList.filter((value) => value.isVisible);
    },
    pdfProgressText() {
      const { done, total, running } = this.pdfArchiveStatus;
      if (!running) return '';
      return this.$t('exportMenu.pdfArchiveStatusText', { done, total });
    },
    isMenuDisabled() {
      return this.disabled || this.menuItems.length === 0;
    },
  },
});
