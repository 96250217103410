
import Vue, { PropType } from 'vue';
import { Location } from 'vue-router';
import { getImageUrl } from '@/helpers/getImageUrl';

export default Vue.extend({
  name: 'SurveyCard',

  props: {
    name: {
      type: String,
      default: '',
    },
    imageUuid: {
      type: String,
      default: '',
    },
    dateEnd: {
      type: String,
      default: '',
    },
    surveyLink: {
      type: Object as PropType<Location | undefined>,
      default: undefined,
    },
  },

  data() {
    return {
      imageError: false,
    };
  },

  computed: {
    isCompleted(): boolean {
      return !!this.dateEnd && this.$dayjs(this.dateEnd).isBefore(this.$dayjs());
    },
    dateStatus(): string {
      if (this.isCompleted) {
        return this.$t('analytics.feedback.endedAt', { date: this.$dayjs(this.dateEnd).format('DD.MM.YY') }) as string;
      }

      return this.$t('analytics.feedback.continues') as string;
    },
    imageSrc(): string {
      if (!this.imageUuid) {
        return '';
      }

      return getImageUrl({
        uuid: this.imageUuid,
        type: 'survey',
        name: 'cover',
        size: null,
      });
    },
  },
});
