
import Vue, { PropType } from 'vue';
import { V1EntitiesPaginateOutput } from '@/services/api/tsxass';
import UserAvatar from '@/components/common/UserAvatar.vue';
import TTSimpleCheckbox from '@/components/ui/TTSimpleCheckbox.vue';
import { UsersTableRow } from './types';

interface UsersTableHeaderItem {
  text: string;
  value: string;
  width: string;
  cellClass?: string;
}

interface Sorting {
  orderBy: string[];
  // true = ASC (по возрастанию), false = DESC (по убыванию)
  direction: boolean[];
}

export default Vue.extend({
  name: 'UsersTable',

  components: {
    UserAvatar,
    TTSimpleCheckbox,
  },

  inject: ['RouteNames'],

  props: {
    users: {
      type: Array as PropType<UsersTableRow[]>,
      default: () => [],
    },
    pagination: {
      type: Object as PropType<V1EntitiesPaginateOutput>,
      required: true,
    },
    headers: {
      type: Array as PropType<UsersTableHeaderItem[]>,
      default(): UsersTableHeaderItem[] {
        return [
          {
            text: this.$t('userForRate.employee') as string,
            value: 'user',
            width: '30%',
          },
          {
            text: this.$t('userForRate.team') as string,
            value: 'user.teamsWithDelimiter',
            width: '40%',
            cellClass: 'one-line-text tt-text-body-2 tt-light-mono-64--text',
          },
          {
            text: '',
            value: 'actions',
            width: '30%',
            cellClass: 'text-end',
          },
        ];
      },
    },
    enableSort: {
      type: Boolean,
      default: false,
    },
    enableSelect: {
      type: Boolean,
      default: false,
    },
    sorting: {
      type: Object as PropType<Sorting>,
      default: () => ({ orderBy: [], direction: [] }),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    usersTotalLeft(): number {
      const res = this.pagination.total - this.pagination.page * this.pagination.limit;
      return res > 0 ? res : 0;
    },
    showMoreButtonLabel(): string {
      if (!this.pagination.limit) {
        return '';
      }

      const count = Math.min(this.pagination.limit, this.usersTotalLeft);
      return this.$t('expertGroups.showMore', { count, total: this.usersTotalLeft }) as string;
    },
    tableOptions: {
      get(): { sortBy: string[]; sortDesc: boolean[] } {
        return {
          sortBy: this.sorting.orderBy,
          sortDesc: this.sorting.direction,
        };
      },
      set(payload: { sortBy: string[]; sortDesc: boolean[] }) {
        this.$emit('update:sorting', {
          orderBy: payload.sortBy,
          direction: payload.sortDesc,
        });
      },
    },
  },
  methods: {
    showMoreUsers() {
      const newPagination = {
        limit: this.pagination.limit,
        page: this.pagination.page + 1,
      };

      this.$emit('load', newPagination);
    },
  },
});
