import uniqBy from 'lodash/uniqBy';

export default function saveLastFilterRequests(userReq) {
  if (!userReq.length) return;

  let savedLastRequests = localStorage.getItem('tt_last_team_search');
  savedLastRequests = JSON.parse(savedLastRequests);

  let chunk = [];

  if (!savedLastRequests) {
    chunk = userReq.length >= 3 ? userReq.slice(-3) : userReq;
  } else if (savedLastRequests.length <= 3) {
    chunk = uniqBy(savedLastRequests.concat(userReq), 'id').slice(-3);
  } else {
    chunk = userReq.slice(-3);
  }

  const prepChunk = chunk.map((item) => ({
    id: item.id, title: item.title || item.name,
  }));
  localStorage.setItem('tt_last_team_search', JSON.stringify(prepChunk));
}
