
import Vue from 'vue';
import { mapState } from 'vuex';
import { tsxassApi } from '@/services/api';
import * as reportServiceApi from '@/services/api/report-service';
import isValidEmail from '@/helpers/emailValidator';
import errorHandler from '@/helpers/errorHandler';

type VForm = Vue & {
  validate: () => boolean;
  resetValidation: () => void;
};

export default Vue.extend({
  name: 'ExportDialog',

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    surveyId: {
      type: Number,
      default: undefined,
    },
    userId: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'pdf',
    },
    fetch: {
      type: Function,
      default: (val: any) => val,
    },
    progress: {
      type: Object,
      default: () => ({ loaded: 0, total: 0 }),
    },
  },
  data() {
    return {
      loading: false,
      loadingUsers: false,
      formData: { ...this.getInitialFormValue() },
      isShowHedgehog: false,
      query: false,
    };
  },

  computed: {
    ...mapState('engagementInfo', { currentUser: 'user' }),
    isDialogVisible: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit('update:show', value);
      },
    },
    validationRules() {
      return {
        notEmpty: [(v: string): boolean | string => !!v?.trim() || (this.$t('commonValidation.required') as string)],
      };
    },
    form(): VForm {
      return this.$refs.form as VForm;
    },
    isEmailValid(): boolean {
      return isValidEmail(this.formData.email);
    },
    dialogTitle(): string {
      if (this.type === 'excel') {
        return this.$t('exportDialog.titleExcel') as string;
      }
      return this.$t('exportDialog.titlePdf') as string;
    },
    computedUserId(): string {
      return this.userId || this.currentUser?.userId;
    },
    percentageValue() {
      return Math.round((this.progress.loaded * 100) / this.progress.total) || 0;
    },
    waitingHours() {
      if (this.type === 'excel') {
        return 2;
      }
      return 6;
    },
  },

  watch: {
    async isDialogVisible(value) {
      if (!value) {
        this.resetForm();
      }
    },
    progress: {
      handler(newVal, oldVal) {
        if (newVal?.loaded > oldVal?.loaded) {
          this.query = false;
        }
      },
      deep: true,
    },
  },

  methods: {
    getInitialFormValue() {
      return {
        email: '',
      };
    },
    async runExportToPdf() {
      try {
        this.loadingUsers = true;
        this.query = true;
        const users = await this.fetch();
        if (users.length === 0) return;

        await reportServiceApi.archiveCreate({
          surveyId: this.surveyId,
          userId: this.computedUserId,
          email: this.formData.email,
          users: users.map((user) => ({
            userId: user.userId,
            userName: `${user.lastName} ${user.firstName}`,
          })),
        });
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.loadingUsers = false;
        this.isShowHedgehog = true;
      }
    },
    async runExportToExcel() {
      try {
        await tsxassApi.postV1AnalyticsManagementXlsxReport({
          surveyId: this.surveyId,
          recipientEmail: this.formData.email,
        });
      } catch (err: any) {
        errorHandler(err);
      } finally {
        this.isShowHedgehog = true;
      }
    },
    async submitForm() {
      if (!this.isEmailValid) return;

      if (this.type === 'excel') {
        await this.runExportToExcel();
        return;
      }
      await this.runExportToPdf();
    },
    resetForm() {
      this.form?.resetValidation();
      this.formData = { ...this.getInitialFormValue() };
      this.isShowHedgehog = false;
      this.$emit('cancel');
    },
    close() {
      this.resetForm();
      this.$emit('cancel');
    },
  },
});
