export type TTTreeItemId = string | number;

export interface TTTreeItem {
  id: TTTreeItemId,
  name: string,
  children: TTTreeItem[],
}

export interface SelectedItemsMapItem {
  item: TTTreeItem,
  selected: boolean,
}

export type SelectedItemsMap = Record<TTTreeItemId, SelectedItemsMapItem>

export enum ValueConsistOf {
  // Если выбран узел ветви, все его потомки не будут включены в результирующий массив
  BRANCH_PRIORITY = 'branch-priority',
  // В результирующий массив будут включены только элементы-листья, промежуточные узлы из ветвей - не будут
  LEAF_PRIORITY = 'leaf-priority',
}
