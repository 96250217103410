
import Vue from 'vue';
import TeamMetaFilters from '@/components/analytics/widgets/TeamMetaFilters.vue';
import EmployeeResultsPivotTable from './EmployeeResultsPivotTable.vue';
import EmployeeResultsComparison from './EmployeeResultsComparison.vue';
import EmployeeDashboards from './EmployeeDashboards.vue';

interface TabItem {
  name: string;
  title: string;
  component: any;
  to: string;
}

export default Vue.extend({
  name: 'TabEmployeeResults',

  components: {
    TeamMetaFilters,
  },

  inject: ['RouteNames'],

  data() {
    return {
      currentTab: null as null | string,
    };
  },

  computed: {
    tabs(): TabItem[] {
      return [
        {
          name: 'pivotTable',
          title: this.$t('analytics.feedback.pivotTable') as string,
          component: EmployeeResultsPivotTable,
          to: this.getTabPath(this.RouteNames.R_APP_USER_ANALYTICS_EMPLOYEE_PIVOT_TABLE),
        },
        {
          name: 'competencyComparison',
          title: this.$t('analytics.feedback.competencyComparison') as string,
          component: EmployeeResultsComparison,
          to: this.getTabPath(this.RouteNames.R_APP_USER_ANALYTICS_EMPLOYEE_COMPETENCY_COMPARISON),
        },
        {
          name: 'employeeDashboards',
          title: this.$t('analytics.feedback.employeeDashboards') as string,
          component: EmployeeDashboards,
          to: this.getTabPath(this.RouteNames.R_APP_USER_ANALYTICS_EMPLOYEE_DASHBOARDS),
        },
      ];
    },
  },

  created() {
    this.initCurrentTab();
  },

  methods: {
    initCurrentTab() {
      this.currentTab = this.tabs.find((tab) => this.$route.path.includes(tab.to))?.to || this.tabs[0].to;
      if (!this.$route.path.includes(this.currentTab)) {
        this.$router.push(this.currentTab);
      }
    },
    getTabPath(routeName: string): string {
      return this.$router.resolve({
        name: routeName,
        query: this.$route.query,
      }).route.path;
    },
  },
});
