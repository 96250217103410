
import { PropType, defineComponent } from 'vue';
import { FILTER_NAMES, META_FILTER_OPTIONS_MOCK } from '@/constants/metaFilters';
import type { TeamMetaFilters, FilterName } from '@/constants/metaFilters';
import SelectTeams from '@/components/analytics/widgets/teams/SelectTeams.vue';

interface Chip {
  filterName: FilterName;
  filterId: string;
  label: string;
}

export default defineComponent({
  name: 'TeamMetaFilters',
  components: {
    SelectTeams,
  },
  props: {
    filters: {
      type: Object as PropType<TeamMetaFilters>,
      default: () => ({
        team: ['team 1.1.1'],
        age: ['1'],
        function: ['1'],
      }),
    },
  },
  data() {
    return {
      META_FILTER_OPTIONS_MOCK,
      isMenuVisible: false,
      currentFilters: this.filters as TeamMetaFilters,
    };
  },
  computed: {
    allFilters() {
      return FILTER_NAMES.map((name) => ({
        name,
        visible: this.filters[name] !== undefined,
        value: this.filters[name],
      })).filter((value) => value.visible);
    },
    visibleFilters() {
      const length = this.$vuetify.breakpoint.smAndDown ? 3 : 4;
      return this.allFilters.slice(0, length);
    },
    menuFilters() {
      return this.allFilters.slice(this.visibleFilters.length);
    },
    chips() {
      const chips = [] as Chip[];
      Object.keys(this.currentFilters).forEach((filterName: FilterName) => {
        this.currentFilters[filterName].forEach((filterId) => {
          chips.push({
            filterName,
            filterId,
            label: META_FILTER_OPTIONS_MOCK[filterName].find((filter) => filter.id === filterId).name,
          });
        });
      });
      return chips;
    },
    teamsTree() {
      return META_FILTER_OPTIONS_MOCK.team;
    },
  },
  watch: {
    filters(newValue: TeamMetaFilters) {
      this.currentFilters = newValue;
    },
  },
  methods: {
    applyFilter(filterName: FilterName, value: string[]) {
      this.currentFilters[filterName] = value;
    },
    clearFilters() {
      Object.keys(this.currentFilters).forEach((filterName: FilterName) => {
        this.currentFilters[filterName] = [];
      });
    },
    removeFilter(chip: Chip) {
      const { filterName } = chip;
      this.currentFilters[filterName] = this.currentFilters[filterName].filter((filter) => filter !== chip.filterId);
    },
  },
});
