
import Vue, { PropType } from 'vue';
import { V1EntitiesAnalyticsManagementSurveysSurvey } from '@/services/api/tsxass';

interface SurveySelectItem {
  name: string,
  id: number | null,
  dateStart?: string,
}

export default Vue.extend({
  name: 'SurveySelect',

  props: {
    value: {
      type: [Number, Object, null] as PropType<number | V1EntitiesAnalyticsManagementSurveysSurvey | null>,
      default: null,
    },
    label: {
      type: String,
      default(): string {
        return this.$t('analytics.feedback.selectSurveyLabel') as string;
      },
    },
    surveys: {
      type: Array as PropType<V1EntitiesAnalyticsManagementSurveysSurvey[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    attach: {
      type: [Boolean, String, Object],
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    // В списке опций есть "Все опросы"
    hasAllSurveysItem: {
      type: Boolean,
      default: false,
    },
    // Показывать лэйбл "Средний балл" напротив опросов
    showAverageScoreLabel: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    surveySelectItems(): SurveySelectItem[] {
      const res = [];
      if (this.hasAllSurveysItem) {
        res.push(
          {
            name: this.$t('analytics.feedback.allSurveys') as string,
            id: null,
          },
        );
      }

      return [
        ...res,
        ...this.surveys.map((survey) => ({
          name: survey.name,
          id: survey.id,
          withBenchAvg: survey.withBenchAvg,
          dateStart: this.$dayjs(survey.dateStart).format('DD.MM.YYYY'),
        })),
      ];
    },
    selectedSurvey: {
      get(): number | V1EntitiesAnalyticsManagementSurveysSurvey | null {
        return this.value;
      },
      set(value: number | V1EntitiesAnalyticsManagementSurveysSurvey | null) {
        this.$emit('input', value);
      },
    },
  },
});
