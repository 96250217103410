<template>
  <TTView>
    <template #right>
      <VRow>
        <VCol>
          <TTBtn
            color="default"
            elevation="1"
            large
            fab
            @click="closeDialog"
          >
            <VIcon> $close </VIcon>
          </TTBtn>
        </VCol>
      </VRow>
    </template>

    <VRow>
      <VCol>
        <div class="tt-text-headline-1 mb-3">
          {{ $t('metafilters.chooseDepartment') }}
        </div>
        <div class="tt-text-body-2 tt-light-mono-64--text">
          {{ $t('metafilters.orgstructureModalSubtitle') }}
        </div>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="6">
        <OrgstructureAutocomplete
          class="mb-3"
          :teams="teams"
          :last-requests="lastRequests"
          @input="onAutocompleteInput"
          @input:item="autocompleteSelectedItem = $event"
        />
      </VCol>
    </VRow>

    <TTTreeSelectable
      v-if="teamsTree.length"
      v-model="selectedNodes"
      :items="teamsTree"
      :open.sync="openNodes"
      :focus-item="autocompleteSelectedItem"
    />

    <VRow class="mt-6">
      <VCol lg="3">
        <TTBtn
          color="tt-secondary-danger"
          class="text-none mr-6"
          @click="closeDialog"
        >
          {{ $t('cancelButton') }}
        </TTBtn>

        <TTBtn
          class="text-none"
          @click="applyTreeTeams"
        >
          {{ $t('apply') }}
        </TTBtn>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TTView from '@/components/ui/TTView.vue';

import TTTreeSelectable from '@/components/ui/TTTree/TTTreeSelectable.vue';
import OrgstructureAutocomplete from '@/components/analytics/widgets/teams/OrgstructureAutocomplete.vue';
import { buildTreeFromFlatArray } from '@/helpers/buildTreeFromFlatArray';
import prepareValueForBranchPriority from '@/components/ui/TTTree/prepareValueForBranchPriority';

export default {
  name: 'OrgstructureTreeView',
  components: { OrgstructureAutocomplete, TTTreeSelectable, TTView },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    teams: {
      type: Array,
      default: () => [],
    },
    lastRequests: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeTeams: [],
      openNodes: [],
      selectedNodes: [],
      autocompleteSelectedItem: null,
    };
  },

  computed: {
    teamsTree() {
      return buildTreeFromFlatArray(this.teams);
    },
  },

  watch: {
    value: {
      handler() {
        this.selectedNodes = this.value || [];
      },
      immediate: true,
    },
  },

  methods: {
    applyTreeTeams() {
      const selectedItems = prepareValueForBranchPriority(this.teamsTree, this.selectedNodes);
      this.$emit('input', selectedItems);
      this.closeDialog();
    },
    closeDialog() {
      this.$emit('close');
    },
    onAutocompleteInput(tree) {
      if (tree === this.teamsTree) {
        return;
      }
      this.openNodes = [];
      const getChildrenLabels = (children) => {
        children.forEach((child) => {
          this.openNodes.push(child);
          getChildrenLabels(child.children || []);
        });
      };
      getChildrenLabels(tree);
    },
  },
};
</script>
