
import Vue, { PropType } from 'vue';
import i18n from '@/plugins/vue-i18n';
import { V1EntitiesAnalyticsManagementExpertListBySurveyItemExpertList } from '@/services/api/tsxass';

const withoutSelfItem = {
  key: null,
  id: null,
  name: i18n.t('analytics.allExpertGroupsWithoutSelf') as string,
  withoutSelf: undefined,
};
const allGroupsItem = {
  key: -2,
  id: null,
  name: i18n.t('analytics.allExpertGroups') as string,
  withoutSelf: false,
};

interface GroupSelectItem {
  id: number | null;
  name: string;
  withoutSelf?: boolean;
}

export default Vue.extend({
  name: 'SelectUsersGroup',

  props: {
    value: {
      type: [Object, null] as PropType<GroupSelectItem | null>,
      default: null,
    },
    groups: {
      type: Array as PropType<V1EntitiesAnalyticsManagementExpertListBySurveyItemExpertList[]>,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    groupSelectItems(): GroupSelectItem[] {
      const mappedGroups = (this.groups || []).map((group) => ({
        ...group,
        key: group.id,
        withoutSelf: undefined,
      }));
      return [withoutSelfItem, allGroupsItem, ...mappedGroups];
    },
  },
});
