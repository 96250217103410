<template>
  <TTAutocomplete
    v-model="input"
    :items="items"
    :placeholder="$t('metafilters.searchTeam')"
    clearable
    item-value="id"
    item-text="name"
    item-disabled="disabled"
    append-icon="$search"
    clear-icon="fal fa-times"
    no-rotate
    return-object
    :search-input.sync="typed"
  >
    <template #item="{ item }">
      <div
        class="mt-2 text-truncate tt-text-body-2"
        data-test="filter-team-selector-item"
      >
        {{ item.name }}
        <div class="tt-text-footnote tt-light-mono-64--text">
          {{ item.parentName }}
        </div>
      </div>
    </template>
  </TTAutocomplete>
</template>

<script>
export default {
  name: 'OrgstructureAutocomplete',
  props: {
    teams: {
      type: Array,
      default: () => [],
    },
    lastRequests: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      input: '',
      typed: '',
      lsItems: [],
    };
  },

  computed: {
    items() {
      if (this.typed) {
        return this.teams;
      }
      const list = [{ header: this.$t('metafilters.lastRequests') }, ...this.lastRequests];
      if (!this.lastRequests.length) {
        list.push({ id: null, name: this.$t('noData'), disabled: true });
      }
      return list;
    },

    filteredTree() {
      if (!this.input) {
        return this.teams;
      }
      return this.teams;
    },
  },

  watch: {
    input(value) {
      this.typed = value?.name;
      if (value) {
        this.lsItems.unshift(value);
        if (this.lsItems.length > 3) {
          this.lsItems.length = 3;
        }
      }
      this.$emit('input', this.filteredTree);
      this.$emit('input:item', this.input);
    },
  },
};
</script>

<style lang="scss" scoped></style>
