export const FILTER_NAMES = ['team', 'function', 'position', 'experience', 'age', 'gender'] as const;

export type FilterName = typeof FILTER_NAMES[number];

export type TeamMetaFilters = Record<FilterName, string[]>;

export type TeamMetaFiltersOptions = Record<FilterName, { id: string; name: string; parentId?: string }[]>;

// TODO: Убрать, когда будем брать данные для метафильтров из нормальной ручки
export const META_FILTER_OPTIONS_MOCK = {
  team: [
    {
      id: 'team 1',
      parentId: null,
      name: 'команда 1',
    },
    {
      id: 'team 1.1',
      parentId: 'team 1',
      name: 'команда 1.1',
    },
    {
      id: 'team 1.2',
      parentId: 'team 1',
      name: 'команда 1.2',
    },
    {
      id: 'team 1.1.1',
      parentId: 'team 1.1',
      name: 'команда 1.1.1',
    },
    {
      id: 'team 1.1.2',
      parentId: 'team 1.1',
      name: 'команда 1.1.2',
    },
    {
      id: 'team 1.1.3',
      parentId: 'team 1.1',
      name: 'команда 1.1.3',
    },
    {
      id: 'team 1.1.4',
      parentId: 'team 1.1',
      name: 'команда 1.1.4',
    },
    {
      id: 'team 1.1.5',
      parentId: 'team 1.1',
      name: 'команда 1.1.5',
    },
    {
      id: 'team 1.1.6',
      parentId: 'team 1.1',
      name: 'команда 1.1.6',
    },
    {
      id: 'team 1.1.7',
      parentId: 'team 1.1',
      name: 'команда 1.1.7',
    },
    {
      id: 'team 1.1.8',
      parentId: 'team 1.1',
      name: 'команда 1.1.8',
    },
    {
      id: 'team 1.1.9',
      parentId: 'team 1.1',
      name: 'команда 1.1.9',
    },
    {
      id: 'team 1.1.10',
      parentId: 'team 1.1',
      name: 'команда 1.1.10',
    },
    {
      id: 'team 1.1.11',
      parentId: 'team 1.1',
      name: 'команда 1.1.11',
    },
    {
      id: 'team 1.1.12',
      parentId: 'team 1.1',
      name: 'команда 1.1.12',
    },
  ],
  age: [
    {
      id: '1',
      name: '22-190',
    },
    {
      id: '2',
      name: '2-21',
    },
  ],
  function: [
    {
      id: '1',
      name: 'function 1',
    },
    {
      id: '2',
      name: 'function 2',
    },
  ],
} as TeamMetaFiltersOptions;
