
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { TsxassAbility } from '@/helpers/constants';
import PageTitle from '@/components/common/PageTitle.vue';
import TabUserResults from './TabUserResults.vue';
import TabEmployeeResults from './TabEmployeeResults.vue';

interface TabItem {
  name: string;
  title: string;
  icon: string;
  component: any;
  to: string;
  acceptTo: string[];
}

export default Vue.extend({
  name: 'UserAnalytics',

  components: {
    TabUserResults,
    TabEmployeeResults,
    PageTitle,
  },

  inject: ['RouteNames'],

  data() {
    return {
      currentTab: null as null | string,
    };
  },

  computed: {
    ...mapGetters('engagementInfo', {
      can: 'can',
    }),
    tabs(): TabItem[] {
      return [
        {
          name: 'myResults',
          title: this.$t('analytics.feedback.myResults') as string,
          icon: 'fal fa-user-tie',
          component: TabUserResults,
          to: this.getTabPath(this.RouteNames.R_APP_USER_ANALYTICS_ME),
          acceptTo: [],
        },
        {
          name: 'employeeResults',
          title: this.$t('analytics.feedback.employeeResults') as string,
          icon: 'fal fa-users',
          component: TabEmployeeResults,
          to: this.getTabPath(this.RouteNames.R_APP_USER_ANALYTICS_EMPLOYEE),
          acceptTo: [this.getTabPath(this.RouteNames.R_APP_USER_ANALYTICS_EMPLOYEE_DASHBOARDS)],
        },
      ];
    },
    showTabs(): boolean {
      return this.can(TsxassAbility.CAN_VIEW_EMPLOYEES_ANALYTICS);
    },
  },

  created() {
    this.initCurrentTab();
  },

  methods: {
    initCurrentTab() {
      const currentTab = this.tabs.find((tab) => [tab.to, ...tab.acceptTo].includes(this.$route.path));
      const currentTabToRedirect = this.showTabs && currentTab ? currentTab : this.tabs[0];
      this.currentTab = currentTabToRedirect.to;
      if (![currentTabToRedirect.to, ...currentTabToRedirect.acceptTo].includes(this.$route.path)) {
        this.$router.push(this.currentTab);
      }
    },
    getTabPath(routeName: string): string {
      return this.$router.resolve({
        name: routeName,
        query: this.$route.query,
      }).route.path;
    },
  },
});
