<template>
  <div>
    <TTAutocomplete
      :value="selected"
      :items="teamsFilterData"
      item-value="id"
      item-text="name"
      item-disabled="disabled"
      :label="$t('metafilters.subdivision')"
      :placeholder="$t('chooseValue')"
      multiple
      :search-input.sync="search"
      append-icon="$search"
      clear-icon="fal fa-times"
      clearable
      no-rotate
      no-filter
      return-object
      @input="updateSelectedTeams"
    >
      <template #item="{ item }">
        <div
          class="mt-2 text-truncate tt-text-body-2"
          data-test="filter-team-selector-item"
        >
          {{ item.name }}
          <div class="tt-text-footnote tt-light-mono-64--text">
            {{ item.parentName }}
          </div>
        </div>
      </template>

      <template #append-item>
        <div class="tt-light-blue--text tt-text-body-2">
          <div class="v-subheader">
            {{ $t('metafilters.allOrgstructure') }}
          </div>
          <div
            class="px-4 py-2 cursor-pointer"
            data-test="orgstructure-modal-open"
            @click="isModalVisible = true"
          >
            {{ $t('metafilters.chooseSubdivision') }}
            <VIcon
              class="ml-1"
              color="tt-light-blue"
              size="10"
            >
              $next
            </VIcon>
          </div>
        </div>
      </template>
    </TTAutocomplete>
    <VDialog
      v-model="isModalVisible"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <VCard>
        <OrgstructureTreeView
          :key="isModalVisible"
          :value="selected"
          :last-requests="lsItems"
          :teams="treeWithParentNames"
          @input="updateSelectedTeams"
          @close="isModalVisible = false"
        />
      </VCard>
    </VDialog>
  </div>
</template>

<script>
import saveLastFilterRequests from '@/helpers/saveLastFilterRequests';
import OrgstructureTreeView from '@/components/analytics/widgets/teams/OrgstructureTreeView.vue';

export default {
  name: 'SelectTeams',
  components: {
    OrgstructureTreeView,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    tree: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: '',
      isModalVisible: false,
      lsItems: [],
    };
  },

  computed: {
    selected() {
      return this.value.map((id) => ({
        id,
        name: this.treeLabels[id].name,
        parentName: this.treeLabels[id].parentName,
      }));
    },
    treeLabels() {
      const treeLabels = this.tree.reduce((acc, item) => ({ ...acc, [item.id]: item }), {});
      this.tree.forEach((item) => {
        if (item.parentId) {
          treeLabels[item.id].parentName = treeLabels[item.parentId].name;
        }
      });
      return treeLabels;
    },
    treeWithParentNames() {
      return Object.values(this.treeLabels);
    },
    teamsFilterData() {
      const list = [];
      const search = this.search?.trim().toLowerCase() || '';
      let searchGroup = this.tree.filter((team) => team.name.toLowerCase().indexOf(search) > -1);
      searchGroup = searchGroup.slice(0, 3);
      if ((search && searchGroup.length) || this.selected.length) {
        list.push(...searchGroup);
      } else if (this.lsItems?.length) {
        list.push(
          { header: this.$t('metafilters.lastRequests') },
          ...this.lsItems.map((item) => ({ ...item, name: item.name || item.title })),
        );
      } else {
        list.push(
          { header: this.$t('metafilters.lastRequests') },
          { id: null, name: this.$t('noData'), disabled: true },
        );
      }
      const listIds = list.map((item) => item.id);
      this.selected.forEach((item) => {
        if (!listIds.includes(item.id)) {
          list.unshift(item);
        }
      });
      return list;
    },
  },

  watch: {
    teams() {
      this.loadLsItems();
    },
  },

  mounted() {
    this.loadLsItems();
  },

  methods: {
    updateSelectedTeams(selectedTeams) {
      const filter = selectedTeams || [];
      this.search = '';
      saveLastFilterRequests(filter);
      this.$emit(
        'input',
        filter.map((item) => item.id),
      );
      this.loadLsItems();
    },
    loadLsItems() {
      const ids = this.tree.map((l) => l.id);
      const items = JSON.parse(localStorage.getItem('tt_last_team_search') || '[]');
      this.lsItems = items
        .filter((i) => ids.indexOf(i.id) > -1)
        .map((item) => ({
          ...item,
          name: item.name || item.title,
          parentName: this.treeLabels[this.treeLabels[item.id]?.parentId]?.name,
        }));
    },
  },
};
</script>

<style lang="scss" scoped></style>
